<template>
  <div
    class="change-amt"
    :class="[
      value * valueMultiplier > 0 ? 'positive-color' : 'negative-color',
      withBackground ? '' : 'no-bg',
      ignoreMargins && !withBackground ? '' : 'add-margin',
      value === 0 ? 'zero' : '',
      iconPosition === 'up' ? 'txt-center' : '',
    ]"
  >
    <font-awesome-icon
      v-if="value !== 0"
      :icon="['fas', value > 0 ? 'caret-up' : 'caret-down']"
      :class="[iconPosition === 'up' ? 'up' : '']"
    />
    {{ formatVal(value) }}
  </div>
</template>

<script>
import formatNumber from '~/util/numeral';

export default {
  props: {
    value: {
      required: true,
      type: Number,
      default: () => 0,
    },
    isInfinity: {
      required: false,
      type: Boolean,
      default: false,
    },
    withBackground: {
      required: false,
      type: Boolean,
      default: false,
    },
    ignoreMargins: {
      required: false,
      type: Boolean,
      default: false,
    },
    decimals: {
      type: String,
      required: false,
      default: () => '',
    },
    iconPosition: {
      required: false,
      type: String,
      default: 'left',
    },
    reverseColors: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valueMultiplier() {
      return this.reverseColors ? -1 : 1;
    },
  },
  methods: {
    formatVal(value) {
      return this.isInfinity ? 'NA' : formatNumber(Math.abs(value), 'PERCENTAGE', this.decimals);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep svg.up {
  display: block;
  margin: auto;
}
.zero {
  color: #1a1f23 !important;
  background-color: #cdd9e0 !important;
}
.negative-color {
  background-color: var(--primaryred);
  &.no-bg {
    top: 0;
    float: left;
    width: 21%;
    margin-top: -10px !important;
    font-size: 12px;
    font-weight: 400;
    color: var(--primaryred);
    text-align: left;
    background-color: transparent;
  }
  &.zero {
    margin-top: 1px;
  }
  &.add-margin {
    margin-top: 0px;
  }
}

.positive-color {
  background-color: var(--primarygreen);
  &.no-bg {
    top: 0px;
    float: left;
    width: 21%;
    font-size: 12px;
    font-weight: 400;
    color: var(--primarygreen);
    text-align: left;
    background-color: transparent;
  }
  &.add-margin {
    margin-top: -10px;
  }
}

.no-bg {
  background-color: transparent;
}
.add-margin.zero {
  margin-left: 0px;
}
.no-bg.zero {
  padding-left: 20px;
  color: #cdd9e0 !important;
  background-color: transparent !important;
}
.change-amt {
  position: relative;
  top: -4px;
  display: inline-block;
  min-width: 40px;
  padding: 0px 6px 0px 10px;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.4em;
  color: #1a1f23;
  text-align: center;
  border-radius: 20px;
  &.txt-center {
    position: inherit;
    text-align: center;
  }
}

.change-amt .fa-caret-down:before {
  padding-right: 2px !important;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fa-caret-up:before {
  content: '\f0d8';
}

.change-amt .fa-caret-up:before {
  padding-right: 2px !important;
}

.change-amt .fa-caret-up:before {
  padding-right: 3px !important;
}

.fa-caret-down:before {
  content: '\f0d7';
}

.change-amt .fa-caret-down:before {
  padding-right: 2px !important;
}

.change-amt .fa-caret-down:before {
  padding-right: 3px !important;
}
</style>
