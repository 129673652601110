var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"change-amt",class:[
    _vm.value * _vm.valueMultiplier > 0 ? 'positive-color' : 'negative-color',
    _vm.withBackground ? '' : 'no-bg',
    _vm.ignoreMargins && !_vm.withBackground ? '' : 'add-margin',
    _vm.value === 0 ? 'zero' : '',
    _vm.iconPosition === 'up' ? 'txt-center' : '',
  ]},[(_vm.value !== 0)?_c('font-awesome-icon',{class:[_vm.iconPosition === 'up' ? 'up' : ''],attrs:{"icon":['fas', _vm.value > 0 ? 'caret-up' : 'caret-down']}}):_vm._e(),_vm._v(" "+_vm._s(_vm.formatVal(_vm.value))+" ")],1)
}
var staticRenderFns = []

export { render, staticRenderFns }